import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../../shared/components/Layouts/Inner';
import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import SEO from '../../shared/components/SEO';
import Hero from '../../shared/components/Hero/index';
import HeroImg from '../../shared/images/banners/commercial.png';
import PressReviews from '../../shared/components/PressReviews';
import WhyChooseUs from '../../shared/components/WhyChooseUs';
import Block from '../../shared/components/Block/index';
import Process from '../../shared/components/Process';
import CtaWrapper from '../../shared/components/CtaBlocks/CtaWrapper';
import CtaBlock from '../../shared/components/CtaBlocks/CtaBlock';
import Testimonials from '../../shared/components/Testimonial/Testimonials';
import AppDownload from '../../shared/components/AppDownload';
import LocationsList from '../../shared/components/Locations/List';

import CommercialPhotos from '../../shared/images/jpg/commercial-photos.jpg';

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding-bottom: 1rem;

  @media (max-width: 500px) {
    padding: 1rem;
  }
`;

export const query = graphql`
  {
    page: contentfulPage(slug: { eq: "commercial-cleaning" }) {
      title
      slug
      frequentlyAskedQuestions {
        id
        title
        childContentfulAccordionBodyRichTextNode {
          id
          json
        }
        __typename
      }
    }
  }
`;

const CommercialCleans = () => (
  <Layout url="commercial-cleaning" darkMode>
    <SEO title="Commercial Cleaning" />
    <Hero image={HeroImg} heading="Commercial Cleaning" showReviews hideServicesBtn>
      <p>Our commercial cleaning services are available throughout the North East.</p>
    </Hero>
    <PressReviews />
    <WhyChooseUs heading="Trusted Commercial Cleaning Services">
      <Block>
        <div>
          <h3>Landlords</h3>
          <p>
            We have a strong bank of landlords who continue to use us on an ongoing basis to help
            ensure their end of tenancy and one time cleans are professionally cleaned to a high end
            level. This allows them to concentrate on the finer tenancy details and be confident
            that their properties are handed over to their new tenants in a sparkling clean
            condition to be proud of.
          </p>
        </div>
        <div>
          <h3>Office Cleans</h3>
          <p>
            Our trustworthy crew routinely cover office and communal wok place cleans. As these
            cleans are mainly key holder positions we habitually send the same trusted cleaner(s) on
            each visit whenever possible. Offices are recognised as areas of high contact so need to
            be disinfected with appropriate antibacterial disinfectant. COVID-19 safety measures are
            key. Familiar cleaners are able to productively operate appropriate infection control on
            an ongoing basis.
          </p>
        </div>
      </Block>
      <br />
      <Block>
        <div>
          <h3>Property Management Teams</h3>
          <p>
            Maidupnorth is a professionally recognised ‘go to’ reliable cleaning service that is
            able to be tailored to individual properties/ property management teams. In high
            turnover periods we work weekends and take block bookings. Not only do we accommodate
            one time light or deep cleans but also carry out weekly and fortnightly HMO/ communal
            house share cleans.
          </p>
        </div>
        <div>
          <h3>Pre/Post Event Cleans</h3>
          <p>
            It is very important to ensure your venue is sparkly fresh to set the right vibes to
            impress. Whatever the occasion our band of maids will work hard to make your work/ team
            event feel welcoming and put your mind at ease that all work or leisure colleagues are
            in a safe and clean environment. Perfect for pre/ during and after.
          </p>
        </div>
      </Block>
    </WhyChooseUs>
    <Process />
    <CtaWrapper>
      <CtaBlock title="Booking">
        <p>
          Our proactive customer service team will help you select the appropriate service via email
          or telephone. We have successfully provided affordable commercial cleaning service rates
          for over 4 years to the North East. Onsite quotes are also available.
        </p>
      </CtaBlock>
      <CtaBlock title="Insurance">
        <p>
          Our commercial cleans are covered by Polished Insurance. Not only has Maidupnorth Ltd
          their own company insurance but we insist on our subcontractors providing an active and
          current policy too.
        </p>
      </CtaBlock>
      <CtaBlock title="Invoicing">
        <p>
          As a VAT registered company we are able to provide weekly, fortnightly or monthly
          invoices. Our invoices can be tailored to suit your needs and will be logoed with our
          professional branding and sent via email to all clients.{' '}
        </p>
      </CtaBlock>
      <CtaBlock title="Payment Method">
        <p>
          Payment is due within 30 days and is done via direct bank transfer or using a secure
          Stripe payment system. If you wish to discuss alternative options please give us a call
          but please be aware we prefer all payments to be made online for tracking purposes.
        </p>
      </CtaBlock>
    </CtaWrapper>
    <StyledImg src={CommercialPhotos} alt="Our Work" />
    <LocationsList />
    <ContainerFixed>
      <Testimonials />
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

export default CommercialCleans;
